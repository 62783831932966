<template>
  <div class="popup_wrap" style="width:800px;max-height: 500px;">
    <!--  popup_wrap -->
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">OOG Notice</h1>
      <div class="content_box mt0 beforetab" style="color:#777777; font-size:11.5px;">
        * LSS 등 부대비용 : <a href="index.html#/common/info/local-charge" target="_blank" style="cursor: pointer; color: blue;">KMTC 선/양하지 부대비용 조회 링크</a><br>
        * 기본 Freetime 조회 : <a href="index.html#/common/info/dem-det-tariff" target="_blank" style="color:blue;cursor:pointer;">기본 Free Time(DEM./DET.)</a> (FR/OT 최대 통합 10일, 필요시, <a href="index.html#/calcIssue/freetime-request" target="_blank"><span style="color:blue;">홈페이지 신청</span></a>)<br>
        * CFS문의 : <a href="index.html#/common/pds" target="_blank" style="color:blue;cursor:pointer;">CFS 문의/연락처</a> ('CFS' 검색후 관련 안내 확인)<br><br>

        * 싱가폴,베트남 도착지 특이사항<br>
        - SIN(Singapore) : OH 120cm, OW 15cm 이상시, OL 양하시 Special Handling Surcharge SGD750 발생(도착지 수화주 청구)<br>
        - SGN(Hochiminh ) : CatLai terminal 모든 OH 화물 양하시 Special Handling Surcharge $500/$800 발생 (운임외 별도 Add-up, 운임지불지 청구)<br><br>

        * FR/OT 수입 건은 가용장비/선복을 현지와 사전 확인 필요<br>
        * 수입 FR/OT의 경우에는, EMPTY&선복 개런티 100%불가하며,  현지에서 가용장비/선복을 사전에 확인하여 주셔야하는 점 당부드립니다.<br>
        만약 귀사 요청으로 장비공급하여 진행할경우, 상기운임에서 $150/$300 ADD-UP 됩니다. (장비공급이 확정 이후 캔슬시에도 청구됨)<br><br>

        * 크레인/차량의 경우 배터리는 부착 불가하고, 연료는 반드시 NIL 상태 유지해야합니다.<br>
        * 최대 화물 중량, 25ton/20FR,  30ton/40FR까지 수용가능<br>
        * FR 양쪽 코너포스트는 접으시면 안되며, WIDTH/HEIGHT OVER 2M인 경우, 실제 선적/양하가능여부는 부킹 후 선/양하지 터미널 추가 확인 필요<br>
        * 모든 한국발 OOG 경우 직반입 원칙, 반입 관련 협조는 선사에서 개런티 불가, 터미널과 확인 후 반입일정 조율<br>
        * OOG승인은 'OOG CENTER(+운항지원+장비재고')' 라는 별도 조직에서 순차적 심사 후 승인.  통상 1~2일(타사 운항모선은 2~3일) 소요됨<br>
      </div><!--content_box -->
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>
  <script>
  import store from '@/store'
  export default {
    name: 'OogNotice',
  data () {
      return {
  //
      }
    },
    computed: {
      serviceLang () {
        return store.getters['auth/serviceLang']
      }
    }
  }
  </script>
<style scoped>
</style>
